#footer-menu {
  list-style: none;
  padding: 0;
  margin: 0 0 vh(21px);
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $Mobile) {
    margin: 0 0 vwm(10px);
    gap: vwm(13px);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  li {
    a {
      font-style: italic;
      font-weight: 700;
      font-size: vh(12px);
      line-height: vh(12px);
      text-decoration-line: none;
      text-transform: uppercase;
      color: $TextColor;
      border-bottom: 1px solid $Color1;
      @media screen and (max-width: $Mobile) {
        font-size: vwm(7.5px);
        line-height: vwm(8px);
      }
      &:hover {
        border: none;
      }
    }
  }
}
.footer {
  background-color: $Color1;
  padding: vh(50px) 0 vh(35px);
  @media screen and (max-width: $Mobile) {
    margin: 0 0 vwm(0);
    padding: vwm(50px) 0 vwm(15px);
  }
  .nav-logo {
    width: vh(128px);
    height: vh(24px);
    margin: vh(18px) 0 vh(27px);
    @media screen and (max-width: $Mobile) {
      width: vwm(109px);
      height: vwm(20px);
      margin: vh(0) 0 vh(30px);
    }
  }
  p {
    font-size: vh(16px);
    line-height: 140%;
    color: #949bb2;
    margin: 0 0 vh(20px);
    @media screen and (max-width: $Mobile) {
      font-size: vwm(16px);
      margin: 0 0 vwm(18px);
      text-align: left;
    }
  }
  .menu {
    &-box {
      display: flex;
      flex-direction: row;
      padding-top: vh(34px);
      gap: vh(30px);
      @media screen and (max-width: $Mobile) {
        flex-direction: column;
        gap: vwm(15px);
        padding-top: vwm(32px);
      }
    }
    &-title {
      font-weight: 500;
      font-size: vh(16px);
      line-height: vh(22px);
      color: #ffffff;
      padding-bottom: vh(20px);
      margin-bottom: vh(31px);
      border-bottom: 0.5px solid #ababab;
      @media screen and (max-width: $Mobile) {
        font-size: vwm(16px);
        line-height: vwm(22px);
        margin-bottom: vwm(5px);
        padding: 0;
        border: 0;
      }
    }
  }
  .footer-menu {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    @media screen and (max-width: $Mobile) {
      padding: 0;
      margin: 0;
    }
    li {
      margin-bottom: vh(15px);
      @media screen and (max-width: $Mobile) {
        margin-bottom: vwm(15px);
      }
      a {
        font-size: vh(14px);
        line-height: 140%;
        display: inline-block;
        color: #949bb2;
        text-decoration: none;
        border-bottom: 1px solid #949bb2;
        &:hover {
          border-color: rgba($color: #fff, $alpha: 0);
        }
        @media screen and (max-width: $Mobile) {
          font-size: vwm(14px);
          border: 0;
          text-decoration: underline;
        }
      }
    }
  }
  &-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: $Mobile) {
      flex-direction: column;
    }
    &-top {
      .footer {
        &-1 {
          width: vh(233px);
          @media screen and (max-width: $Mobile) {
            width: 100%;
          }
        }
        &-2 {
          width: vh(266px);
          @media screen and (max-width: $Mobile) {
            width: 100%;
          }
          .question {
            color: #468dd1;
            font-size: vh(16px);
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            display: inline-block;
            cursor: pointer;
            border-bottom: 1px solid #bcd8f2;
            &:hover {
              border-color: rgba($color: #fff, $alpha: 0);
            }
            @media screen and (max-width: $Mobile) {
              font-size: vwm(16px);
              margin-bottom: vwm(30px);
            }
          }
        }
        &-3 {
          width: vh(741px);
          @media screen and (max-width: $Mobile) {
            width: 100%;
          }
        }
      }
    }
    &-2 {
      margin-top: vh(64px);
      padding-top: vh(40px);
      border-top: 1px solid #e8e8e8;
      @media screen and (max-width: $Mobile) {
        margin-top: vwm(37px);
        padding-top: vwm(30px);
      }
    }
  }
  &-phone {
    color: #1c2132;
    font-size: vh(24px);
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-decoration: none;
    margin: vh(15px) 0 vh(5px);
    display: inline-block;
    @media screen and (max-width: $Mobile) {
      font-size: vwm(20px);
      line-height: 140%;
      margin: vwm(30px) 0 vwm(5px);
    }
  }
  &-info {
    padding: vh(20px) vh(50px) vh(20px) vh(30px);
    background-image: url("data:image/svg+xml,%3Csvg width='741' height='139' viewBox='0 0 741 139' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.141063 22.551C0.142033 21.7566 0.458019 20.9951 1.01972 20.4334L20.4057 1.04691C20.9682 0.484435 21.731 0.168369 22.5265 0.168212L724.997 0.0295266C733.835 0.0277818 741 7.19174 741 16.0295V115.926C741 116.722 740.684 117.485 740.121 118.048L721.021 137.148C720.459 137.71 719.696 138.026 718.9 138.026H16.0195C7.17533 138.026 0.00876531 130.851 0.0195692 122.007L0.141063 22.551Z' fill='%23D7EBFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.141063 22.551C0.142033 21.7566 0.458019 20.9951 1.01972 20.4334L20.4057 1.04691C20.9682 0.484435 21.731 0.168369 22.5265 0.168212L724.997 0.0295266C733.835 0.0277818 741 7.19174 741 16.0295V115.926C741 116.722 740.684 117.485 740.121 118.048L721.021 137.148C720.459 137.71 719.696 138.026 718.9 138.026H16.0195C7.17533 138.026 0.00876531 130.851 0.0195692 122.007L0.141063 22.551Z' fill='%23F1F6FE'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    @media screen and (max-width: $Mobile) {
      background-image: url("data:image/svg+xml,%3Csvg width='335' height='272' viewBox='0 0 335 272' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.141868 23.0658C0.142323 22.2708 0.458349 21.5084 1.02052 20.9463L20.4061 1.56027C20.9683 0.997979 21.7309 0.681927 22.526 0.681566L318.993 0.547289C327.832 0.543286 335 7.7079 335 16.5473V248.94C335 249.736 334.684 250.499 334.121 251.061L315.021 270.161C314.459 270.724 313.696 271.04 312.9 271.04H16.0092C7.16903 271.04 0.00410145 263.871 0.00915895 255.031L0.141868 23.0658Z' fill='%23D7EBFE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.141868 23.0658C0.142323 22.2708 0.458349 21.5084 1.02052 20.9463L20.4061 1.56027C20.9683 0.997979 21.7309 0.681927 22.526 0.681566L318.993 0.547289C327.832 0.543286 335 7.7079 335 16.5473V248.94C335 249.736 334.684 250.499 334.121 251.061L315.021 270.161C314.459 270.724 313.696 271.04 312.9 271.04H16.0092C7.16903 271.04 0.00410145 263.871 0.00915895 255.031L0.141868 23.0658Z' fill='%23F1F6FE'/%3E%3C/svg%3E%0A");
      padding: vwm(20px) vwm(23px) vwm(20px) vwm(20px);
    }
    > div {
      font-size: vh(16px);
      font-style: normal;
      line-height: 140%;
      @media screen and (max-width: $Mobile) {
        font-size: vwm(16px);
      }
    }
    &-title {
      color: #052994;
      font-weight: 700;
      margin-bottom: vh(10px);
      @media screen and (max-width: $Mobile) {
        margin-bottom: vwm(10px);
      }
    }
    &-text {
      font-weight: 400;
      color: #575f79;
      letter-spacing: vh(-0.1px);
    }
  }
  &-content {
    background: $Color8;
    border-radius: vh(40px);
    padding: vh(30px) vh(50px) vh(20px);
    @media screen and (max-width: $Mobile) {
      border-radius: vwm(25px);
      padding: vwm(28px) vwm(13px) vwm(20px);
    }
  }
  .copy {
    font-weight: 400;
    font-size: vh(14px);
    line-height: 140%;
    text-align: center;
    color: #949bb2;
    @media screen and (max-width: $Mobile) {
      font-size: vwm(14px);
      text-align: left;
      margin-bottom: vwm(20px);
      > div {
      }
    }
  }
}
