.header {
  position: absolute;
  width: 100%;
  z-index: 12;
  padding: vh(20px) 0;
  background: #d7ebfe;
  @media (min-width: $DesctopeMin) {
    &__menu-mobile {
      display: none !important;
    }
  }
  @media (max-width: $Mobile) {
    padding: vwm(20px) 0 vwm(22px);
    z-index: 12;
    &.active {
      position: fixed;
    }
    .btn {
      border-color: #fee1c9;
      color: #fff;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > .block-check {
      @media (max-width: $Mobile) {
        display: none;
      }
    }
    &-m {
      display: flex;
      @media (max-width: $Mobile) {
        flex-direction: column;
        width: 100%;
        padding: 0 vwm(20px);
      }
    }
  }
  &.top {
    position: fixed;
    background-color: $Color2;
    @media (min-width: $DesctopeMin) {
      .block-check {
        display: flex;
      }
    }
  }
}

.logo__box {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $Mobile) {
    z-index: 10;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .logo {
    width: vh(123px);
    img {
      width: vh(123px);
      height: vh(24px);
      @media (max-width: $Mobile) {
        width: vwm(105px);
        height: vwm(20px);
      }
    }
  }
}

#menu {
  margin: 0 vh(60px) 0 vh(0px);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: vh(40px);
  @media (max-width: $Mobile) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    padding: 0;
    width: 100%;
    margin: 0 0 vwm(45px);
  }
  li {
    display: inline;
    padding: 0 vh(0px);
    text-align: left;
    position: relative;
    @media (max-width: $Mobile) {
      width: 100%;
    }

    a {
      font-size: vh(16px);
      line-height: vh(15px);
      color: $TextHeaderMenu;
      position: relative;
      text-decoration: none;
      @media (max-width: $Mobile) {
        font-size: vwm(20px);
        line-height: 1;
        font-weight: 400;
        text-transform: initial;
        display: flex;
        padding: vwm(15px) vwm(0);
        color: $Color1;
      }
      .ico-regulations {
        background-size: cover;
        margin: vh(-1px) 0 0 vh(6px);
      }

      &:hover {
        // color: $Color2;
      }
    }

    &.active a {
      &:after {
        content: '';
        position: absolute;
        bottom: vh(-5px);
        left: 0;
        width: 100%;
        background: $Color2;
        border: 1px solid $Color1;
      }
    }
  }
}

.header__btn {
  position: absolute;
  right: vwm(20px);
  top: vwm(0px);
  margin-top: vwm(0px);
  z-index: 10;
  display: none;
  @media (max-width: $Mobile) {
    display: flex;
  }
}
.header__menu-mobile {
  display: none;
  &-2 {
    position: absolute;
    top: vwm(578px);
    padding: 0 0 0 vwm(23px);
    .pravila {
      color: #ababab;
      font-size: vwm(16px);
      line-height: vwm(15px);
      margin-bottom: vwm(16px);
      display: block;
      text-decoration: none;
    }
    ul {
      margin: 0;
      display: flex;
      flex-direction: row;
      padding: 0;
      li {
        border-right: 1px solid #6b6863;
        padding: 0 vwm(5px) 0 0;
        &:last-child {
          border: 0;
          padding: 0 0 0 vwm(5px);
        }
        a {
          color: #6b6863;
          font-size: vwm(16px);
          line-height: vwm(19px);
          text-decoration: none;
        }
      }
    }
  }
}
.lang {
  position: absolute;
  left: 0;
  top: calc(50% - 1.26953vh);
  transform: translateY(-50%);
  @media (max-width: $Mobile) {
    display: flex;
    flex-direction: column-reverse;
    top: 1.8vw;
    left: 10vw;
    transform: initial;
  }
  button {
    background: none;
    border: none;
    font-size: vh(20px);
    font-weight: 700;
    line-height: vh(22px);
    color: #ffffff;
    border-right: 2px solid #fff;
    @media (max-width: $Mobile) {
      font-size: vwm(14px);
      line-height: vwm(16px);
      border: 0;
      padding: 0.8vw 0;
    }
    &:last-child {
      border: 0;
    }
  }
}
.header__right {
  display: flex;
  align-content: center;
  align-items: center;
  transition: all 0.5s;
  .container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: $Mobile) {
  }
  @media (max-width: $Mobile) {
    display: none;
    &.active {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      overflow-y: scroll;
      display: flex;
      z-index: 9;
      background: #052994;
      align-items: flex-start;
      padding: vwm(95px) 0 0;
      width: 100%;
    }
    .logo__box {
      display: none;
    }
  }
}
.hamburger {
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  border-radius: vh(12px);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;
  z-index: 1;
  position: relative;
}

@media (orientation: landscape) and (max-width: 19999px) {
  .hamburger {
    margin-left: 1.30208vmin;
    width: vwm(31px);
    height: vwm(31px);
  }
}

@media (orientation: portrait) and (min-width: $Mobile) {
  .hamburger {
    width: vwm(19px);
    height: vwm(19px);
    top: vwm(26px);
  }
}

@media (orientation: portrait) and (max-width: $Mobile) {
  .hamburger {
    margin-left: 3.125vmin;
    width: vwm(19px);
    height: vwm(19px);
    margin: 0;
    border-radius: vwm(12px);
    &.-active {
    }
  }
}

.hamburger .hamburger-box {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: vwm(-3px);
}

@media (orientation: landscape) and (max-width: 19999px) {
  .hamburger .hamburger-box {
    width: vwm(28px);
    height: vwm(28px);
  }
}

@media (orientation: portrait) and (min-width: 768px) {
  .hamburger .hamburger-box {
    width: vwm(28px);
    height: vwm(28px);
  }
}

@media (orientation: portrait) and (max-width: $Mobile) {
  .hamburger .hamburger-box {
    width: vwm(21px);
    height: vwm(17px);
  }
}

.hamburger .hamburger-inner {
  width: 100%;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition-duration: 0.22s;
  transition-duration: 0.22s;
  top: 50%;
}

.hamburger .hamburger-inner,
.hamburger .hamburger-inner:after,
.hamburger .hamburger-inner:before {
  position: absolute;
  height: vwm(2px);
  background-color: #052994;
  display: block;
}

.hamburger .hamburger-inner:after,
.hamburger .hamburger-inner:before {
  content: '';
  border-radius: 2px;
}

.hamburger .hamburger-inner:before {
  width: 100%;
  top: vwm(-8px);
  -webkit-transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
  transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
}

.hamburger .hamburger-inner:after {
  width: 100%;
  -webkit-transition: bottom 0.1s ease-in 0.25s, width 0.2s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.25s, width 0.2s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.25s, width 0.2s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.25s, width 0.2s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  bottom: vwm(-8px);
  right: 0;
  border-radius: 2px;
}

.hamburger.-active .hamburger-inner {
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  background-color: #fff;
}

.hamburger.-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
  transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
  opacity: 0;
  width: 100%;
}

.hamburger.-active .hamburger-inner:after {
  width: 100%;
  bottom: 0;
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transition: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s,
    -webkit-transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: #fff;
}
