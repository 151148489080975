@keyframes btnCheckArrow1 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}
@keyframes btnCheckArrow2 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}
@keyframes btnCheckArrow3 {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: translate(-15px, 0px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(2px, 0px);
  }
}
@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes star {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  70% {
    transform: rotateY(200deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes rotate-inverse {
  0% {
    transform: rotateY(180deg);
  }
  70% {
    transform: rotateY(-20deg);
  }
  100% {
    transform: rotateY(0);
  }
}

// не используются
// @keyframes scale-up-center {
//   0% {
//     transform: translateX(0);
//     opacity: 0;
//   }
//   20% {
//     transform: translateX(0);
//     opacity: 1;
//   }
//   40% {
//     transform: translateX(0px);
//     opacity: 0;
//   }
//   60% {
//     transform: translateX(15px);
//     opacity: 1;
//   }
//   80% {
//     transform: translateX(15px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(30px);
//     opacity: 1;
//   }
// }
// @keyframes scale-up-center2 {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
// @keyframes scale-up-center3 {
//   0% {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
