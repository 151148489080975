@font-face {
  font-family: 'Arame';
  src: local('Arame Bold'), local('Arame-Bold'), url('../fonts/Arame-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arame';
  src: local('Arame'), url('../fonts/Arame.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arame';
  src: local('Arame Stencil'), local('Arame-Stencil'),
    url('../fonts/Arame-Stencil.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arame';
  src: local('Arame Thin'), local('Arame-Thin'), url('../fonts/Arame-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
