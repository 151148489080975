@import 'fonts.scss';

$browser-context-vw: 14.4;

@function vw($pixels, $context: $browser-context-vw) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1vw;
}
$browser-context-vh: 8.8;

@function vh($pixels, $context: $browser-context-vh) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1vh;
}
$browser-context-vh2: 7;

@function vh2($pixels, $context: $browser-context-vh2) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1vh;
}

$browser-context-mobile: 3.75;

@function vwm($pixels, $context: $browser-context-mobile) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1vw;
}

$browser-context-mobile-landscape: 3.75;

@function vhm($pixels, $context: $browser-context-mobile-landscape) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1vh;
}
$mobile: 'max-width: 767px';
$Color1: #fff;
$Color2: #052994;
$Color3: #9bcdfd;
$ColorOrange: #f2bd30;
$ColorGreen: #3dae2b;
$Color4: #392a26;
$Color5: #42b271;
$Color6: #f1bc41;
$Color7: #312c71;
$Color8: #0097d7;
$Color9: #ff8403;
$ColorPlacehold: #575f79;
$TextColor: #002497;
$TextColor2: #052994;
$TextHeaderMenu: #002497;
$Planshet: 1023px;
$Retina: 1921px;
$DesctopeMax: 1366px;
$Desctope: 1024px;
$DesctopeMinMax: 1206px;
$DesctopeMin: 1024px;
$PlanshetMax: 1023px;
$Mobile: 1023px;
$MobileLand: 828px;
$MobileLandW: 1024px;
$font: 'PT Sans', sans-serif;
$font2: 'Arame';
$font3: 'PT Sans', sans-serif;
* {
  line-height: 100%;
  transition: all 0.1s ease-in;
  outline: none !important;
  scroll-behavior: auto;
  scroll-behavior: smooth;
}

body {
  font: 16px/20px $font;
  position: relative;
  height: 100%;
  color: $TextColor;
  overflow-x: hidden;
  font-weight: normal;
  margin: 0;
  background: #d7ebfe;
}
#root {
  overflow: hidden;
  @media screen and (max-width: $Mobile) {
    overflow: hidden;
  }
}
@import 'constant.scss';
@import 'ico.scss';
@import 'header.scss';
@import 'content.scss';
@import 'footer.scss';
@import 'animations.scss';
@import 'modal-style.scss';
@import 'form.scss';
.color-orange {
  color: $ColorOrange;
}

/* mobile style*/
@media (max-width: 1024px) {
}

:root {
  --off-white: #fafafa;
}
body > iframe {
  display: none;
}
